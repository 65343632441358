import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
<path d="M5530 10775 c-1083 -82 -2066 -487 -2895 -1194 -129 -110 -406 -387
-521 -521 -382 -447 -666 -921 -877 -1465 -446 -1149 -443 -2454 10 -3605 71
-182 229 -511 316 -663 369 -637 862 -1178 1452 -1593 1157 -815 2580 -1097
3979 -788 937 207 1855 732 2517 1439 759 810 1213 1802 1336 2915 26 233 25
776 0 1010 -158 1435 -881 2687 -2039 3532 -930 679 -2127 1020 -3278 933z
m775 -140 c1171 -110 2210 -594 3015 -1404 860 -865 1356 -1991 1410 -3202 50
-1113 -275 -2188 -934 -3089 -107 -146 -155 -206 -271 -340 -256 -295 -669
-652 -985 -852 -30 -19 -75 -47 -100 -63 -281 -179 -705 -376 -1042 -484 -934
-299 -1870 -319 -2818 -61 -393 108 -882 320 -1220 532 -336 210 -599 416
-859 671 -218 216 -358 378 -541 629 -423 580 -716 1276 -844 2008 -104 591
-92 1262 31 1820 131 599 325 1082 631 1575 554 892 1415 1599 2395 1964 408
152 798 245 1204 285 81 9 164 17 183 19 104 11 600 6 745 -8z"/>
<path d="M3280 8350 c0 -115 1 -210 3 -210 1 0 119 -20 262 -45 143 -25 263
-45 268 -45 4 0 7 -106 7 -235 l0 -235 325 0 325 0 0 235 0 235 438 0 c240 0
489 -5 552 -11 255 -23 458 -87 629 -197 74 -49 191 -141 191 -152 0 -5 -30
-30 -67 -56 -202 -144 -347 -313 -457 -532 -113 -226 -156 -415 -156 -692 1
-372 76 -651 241 -897 128 -190 340 -360 603 -484 63 -29 116 -59 116 -65 0
-14 -109 -181 -159 -244 -56 -71 -160 -171 -236 -228 -81 -61 -299 -174 -328
-170 -12 2 -39 41 -81 118 l-63 115 -58 3 -58 3 4 -153 4 -153 -40 -8 c-22 -4
-273 -11 -557 -14 l-518 -5 0 1416 0 1416 -325 0 -325 0 0 -1409 c0 -776 -4
-1412 -8 -1415 -4 -2 -113 -23 -242 -45 -129 -23 -247 -44 -262 -47 l-28 -6 0
-209 0 -209 1003 0 c551 0 1074 5 1162 10 87 5 161 8 162 6 2 -2 11 -176 19
-387 8 -211 17 -399 20 -416 5 -31 7 -33 47 -30 41 2 43 4 77 69 90 172 112
174 620 63 181 -40 394 -78 565 -101 158 -21 566 -30 707 -14 141 15 341 65
475 117 640 252 1010 854 980 1598 -18 428 -137 735 -382 981 -246 247 -559
396 -1210 576 l-120 33 -6 265 c-7 288 -20 406 -70 597 -56 220 -141 412 -263
597 -47 71 -59 96 -49 101 30 11 253 4 336 -10 331 -58 650 -287 952 -685 96
-127 189 -269 309 -472 l99 -168 53 0 c47 0 54 2 54 20 0 54 -40 1179 -46
1278 l-6 112 -32 0 c-25 0 -37 -8 -66 -47 -60 -80 -77 -88 -188 -87 -81 0
-120 7 -232 38 -377 106 -492 131 -720 156 -128 15 -434 14 -542 -1 l-88 -12
-97 93 c-317 304 -656 463 -1143 538 -91 13 -259 16 -1242 19 l-1138 4 0 -211z
m3224 -933 c122 -196 195 -410 231 -677 14 -104 20 -280 10 -280 -27 0 -197
114 -258 174 -62 60 -120 152 -149 234 -20 59 -23 86 -23 217 0 136 3 157 26
220 26 73 92 185 108 185 5 0 30 -33 55 -73z m846 -2697 c444 -139 655 -244
816 -404 132 -133 182 -242 203 -451 42 -405 -210 -708 -662 -796 -107 -21
-279 -25 -387 -9 -359 52 -765 313 -1105 710 l-77 90 39 16 c401 165 723 439
942 807 29 48 57 87 61 87 5 0 81 -23 170 -50z"/>
<path d="M4793 3626 c-35 -16 -68 -58 -60 -78 4 -9 6 -7 6 4 1 9 15 28 33 43
30 25 37 27 91 21 32 -3 63 -8 68 -12 11 -6 12 -84 2 -84 -5 0 -18 16 -30 35
-34 52 -105 63 -111 17 -5 -31 10 -46 60 -61 62 -19 87 -39 99 -80 15 -49 -7
-103 -51 -126 -47 -24 -156 -12 -164 19 -7 26 -8 76 -1 76 3 0 25 -20 48 -45
59 -61 117 -60 117 3 0 18 -10 27 -47 43 -82 35 -94 42 -108 69 -8 14 -14 20
-15 15 0 -6 7 -20 15 -32 14 -20 14 -23 -1 -31 -9 -5 -19 -24 -23 -43 -7 -32
-10 -26 -55 98 -27 73 -55 138 -62 144 -29 24 -59 2 -81 -58 -11 -32 -24 -57
-29 -56 -5 2 -11 29 -14 60 l-5 58 -134 3 c-74 1 -138 -1 -142 -5 -4 -4 -9
-37 -11 -73 -3 -60 -1 -65 19 -68 16 -2 24 4 33 32 21 65 31 40 28 -71 -3
-108 -3 -108 -28 -108 -23 0 -29 11 -75 137 -28 76 -56 143 -62 149 -6 6 -21
9 -34 7 -20 -2 -30 -19 -59 -95 -41 -108 -50 -120 -50 -68 0 58 -33 118 -77
140 -32 16 -65 20 -210 23 -156 3 -174 2 -180 -13 -3 -10 -1 -26 5 -36 14 -23
15 -59 2 -79 -8 -12 -10 -9 -10 13 0 38 -54 92 -105 106 -22 6 -80 11 -128 11
-95 0 -116 -12 -83 -49 21 -23 24 -245 4 -239 -7 3 -35 66 -63 141 -34 92 -56
139 -67 143 -39 12 -55 -5 -87 -94 -42 -116 -47 -122 -49 -62 -1 62 -25 107
-72 134 -29 17 -53 20 -145 21 -107 0 -110 -1 -113 -22 -2 -13 1 -23 7 -23 7
0 11 -43 11 -125 0 -77 -4 -125 -10 -125 -5 0 -10 -9 -10 -20 0 -13 9 -22 26
-26 38 -10 177 3 217 19 31 13 35 13 45 -4 12 -19 54 -25 86 -13 18 7 22 40 5
46 -7 2 -9 13 -5 26 10 30 62 31 71 2 4 -12 1 -27 -5 -35 -10 -12 -10 -18 1
-31 20 -24 337 -16 391 10 38 18 78 70 78 100 0 10 5 14 10 11 14 -8 13 -61
-2 -84 -6 -10 -8 -26 -4 -36 6 -16 21 -17 174 -13 96 3 181 10 199 18 28 10
35 9 49 -5 11 -10 29 -15 53 -13 36 3 36 3 36 48 0 43 1 45 32 48 37 4 52 -24
31 -58 -11 -17 -11 -22 1 -30 8 -4 108 -9 222 -9 168 0 210 3 220 15 9 11 9
17 -1 29 -20 24 -3 55 31 55 35 0 46 -21 31 -55 -8 -17 -8 -26 1 -34 18 -14
317 -17 344 -3 12 7 31 23 42 38 l20 25 16 -24 c40 -62 167 -69 223 -14 23 23
25 34 30 141 2 64 9 116 13 116 14 0 11 -217 -3 -239 -23 -37 -4 -51 69 -51
178 2 252 56 204 152 -14 29 -17 46 -11 59 18 33 11 79 -15 103 -36 34 -163
46 -285 28 -37 -6 -46 -11 -48 -29 -2 -13 1 -23 7 -23 5 0 12 -8 14 -17 4 -15
5 -14 6 3 0 11 -4 25 -11 32 -9 9 -4 12 23 11 18 0 28 -3 22 -6 -10 -3 -13
-38 -13 -124 0 -113 -1 -121 -24 -144 -47 -47 -158 -41 -192 10 -9 13 -18 51
-21 84 -4 36 -10 59 -17 58 -6 -1 -11 7 -11 18 0 12 6 19 14 17 10 -2 12 8 9
43 l-3 45 65 -1 c36 0 59 -3 53 -6 -10 -3 -13 -39 -13 -128 0 -113 2 -125 19
-135 48 -25 102 2 100 51 -1 21 -2 20 -10 -7 -11 -39 -34 -49 -65 -29 -23 15
-24 21 -24 117 0 84 3 103 17 113 14 10 15 16 5 27 -8 11 -44 17 -119 21 -60
4 -124 9 -143 12 -20 3 -48 -1 -67 -9z m-1974 -34 c55 -27 73 -60 73 -130 0
-70 -19 -110 -65 -137 -37 -22 -227 -35 -227 -16 0 6 5 11 10 11 15 0 13 263
-2 278 -21 21 169 16 211 -6z m281 -127 c29 -80 57 -145 61 -145 5 0 9 -4 9
-10 0 -6 -28 -10 -64 -10 -36 0 -68 5 -71 10 -3 6 1 10 9 10 13 0 13 5 2 38
-12 35 -15 37 -56 37 -41 0 -44 -2 -56 -37 -11 -33 -11 -38 2 -38 8 0 12 -4 9
-10 -7 -11 -48 -14 -58 -3 -4 3 0 11 8 17 7 6 33 63 56 126 23 63 45 125 50
138 5 12 17 22 27 22 16 0 30 -28 72 -145z m298 130 c52 -21 76 -65 76 -135 0
-67 -23 -114 -67 -137 -39 -20 -227 -32 -227 -14 0 6 5 11 10 11 16 0 13 277
-2 283 -7 3 29 6 79 6 61 1 105 -4 131 -14z m224 3 c-15 -15 -17 -278 -2 -278
6 0 10 -4 10 -10 0 -6 -26 -10 -59 -10 -33 0 -63 5 -66 10 -3 6 -1 10 4 10 17
0 15 277 -1 283 -7 3 18 6 57 6 54 1 66 -2 57 -11z m237 -4 c57 -23 76 -56 76
-134 0 -114 -43 -151 -188 -158 -58 -3 -92 -1 -100 7 -8 8 -8 11 1 11 18 0 17
276 0 283 -7 3 29 6 79 6 63 1 105 -4 132 -15z m283 -124 c27 -74 54 -139 59
-145 19 -19 7 -25 -56 -25 -37 0 -65 4 -65 10 0 6 4 10 9 10 6 0 4 16 -3 38
-12 34 -17 37 -53 40 -39 3 -42 2 -53 -34 -10 -28 -10 -39 -1 -42 19 -6 2 -22
-25 -22 -23 0 -33 14 -16 22 4 2 18 32 31 68 72 199 83 221 103 218 17 -2 31
-30 70 -138z m316 83 c2 -29 -1 -53 -5 -53 -4 1 -17 20 -28 43 -11 23 -28 43
-37 45 -16 3 -18 -8 -18 -132 0 -114 2 -136 15 -136 8 0 15 -4 15 -10 0 -6
-30 -10 -69 -10 -39 0 -73 5 -76 10 -3 6 1 10 9 10 14 0 16 19 16 135 0 74 -3
135 -7 135 -18 -1 -33 -18 -48 -53 -19 -46 -40 -50 -31 -6 3 17 6 42 6 56 l0
24 128 -3 127 -3 3 -52z m187 -85 c28 -75 54 -140 58 -144 17 -18 3 -24 -52
-24 -53 0 -88 13 -61 22 6 2 6 16 -2 41 -11 35 -14 37 -53 37 -38 0 -42 -2
-54 -37 -10 -27 -11 -38 -2 -41 22 -7 10 -22 -19 -22 -27 0 -41 14 -22 22 7 3
21 36 92 231 18 49 25 58 43 55 18 -2 31 -27 72 -140z m836 115 c27 -27 28
-69 1 -95 l-20 -21 29 -24 c52 -44 31 -113 -39 -133 -48 -13 -192 -13 -192 0
0 6 5 10 10 10 15 0 13 263 -2 279 -11 10 6 12 89 9 89 -3 105 -6 124 -25z
m-598 -25 c36 -36 26 -46 -28 -28 -42 14 -51 26 -35 45 18 21 28 19 63 -17z
m-1233 -103 c0 -70 -4 -115 -10 -115 -6 0 -10 45 -10 115 0 70 4 115 10 115 6
0 10 -45 10 -115z m780 59 c10 -30 16 -35 36 -31 29 5 29 3 2 -70 -45 -123
-68 -111 -68 38 0 113 8 130 30 63z m414 -124 c32 -13 46 -25 46 -39 0 -46
-71 -27 -103 28 -22 38 -11 40 57 11z"/>
<path d="M2710 3459 l0 -139 24 0 c45 0 66 42 66 134 0 97 -13 128 -58 137
l-32 6 0 -138z m58 99 c15 -15 16 -171 2 -199 -6 -10 -17 -19 -25 -19 -12 0
-15 21 -15 115 0 117 6 135 38 103z"/>
<path d="M2982 3490 c-26 -67 -26 -70 7 -70 34 0 36 7 16 60 l-13 35 -10 -25z"/>
<path d="M3295 3592 c-3 -3 -5 -65 -5 -139 0 -131 0 -133 23 -133 50 0 67 35
67 142 0 92 -13 122 -54 130 -14 3 -28 3 -31 0z m66 -64 c21 -80 -6 -205 -40
-184 -12 8 -16 208 -4 219 16 17 33 4 44 -35z"/>
<path d="M3755 3592 c-3 -3 -5 -65 -5 -139 0 -131 0 -133 23 -133 50 0 67 35
67 142 0 92 -13 122 -54 130 -14 3 -28 3 -31 0z m65 -41 c13 -25 13 -151 0
-185 -5 -14 -16 -26 -25 -26 -13 0 -15 19 -15 115 0 94 3 115 15 115 8 0 19
-9 25 -19z"/>
<path d="M4017 3467 l-16 -47 30 0 c32 0 34 7 15 60 l-13 34 -16 -47z"/>
<path d="M4530 3498 c0 -3 -7 -22 -15 -42 l-15 -36 34 0 c35 0 35 0 24 33 -10
29 -28 59 -28 45z"/>
<path d="M5370 3535 c0 -58 2 -66 18 -63 13 2 18 15 20 55 3 44 0 53 -17 63
-20 10 -21 8 -21 -55z"/>
<path d="M5370 3384 c0 -61 1 -65 22 -62 12 2 23 9 26 17 3 8 0 11 -7 7 -7 -5
-11 6 -11 35 0 45 15 44 23 -1 4 -24 4 -24 6 3 1 38 -15 67 -39 67 -18 0 -20
-7 -20 -66z"/>

</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
